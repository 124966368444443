import { Box, Typography } from '@mui/material';

import { DownloadIcon } from '~/shared/icons/DownloadIcon';

interface DownloadButtonProps {
  label: string;
  onClick: () => void;
}

export const DownloadButton = ({ label, onClick }: DownloadButtonProps) => {
  return (
    <Box
      component="a"
      sx={{
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        cursor: 'pointer',
        color: '#2B52DD',
      }}
    >
      <DownloadIcon color="#2B52DD" size={14} />
      <Typography
        sx={{
          fontWeight: 500,
          textDecoration: 'underline',
          color: '#2B52DD',
          marginLeft: 1,
        }}
        onClick={onClick}
      >
        {label}
      </Typography>
    </Box>
  );
};
