import { Box } from '@mui/material';

import { StyledCampaignFormRow } from '~/entities/Campaign/components/elements';

import { DeleteButton } from './Buttons/DeleteButton';
import { DownloadButton } from './Buttons/DownloadButton';
import { FileUploadButton } from './Buttons/FileUploadButton';

interface CnpjActionsRowProps {
  onUploadClick: () => void;
  onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  fileInputRef: React.RefObject<HTMLInputElement>;
  onDownload: () => void;
  onDelete: () => void;
  onDownloadDefault: () => void;
  isCnpjList: boolean;
}

export const CnpjActionsRow = ({
  onUploadClick,
  onFileChange,
  fileInputRef,
  onDownload,
  onDelete,
  onDownloadDefault,
  isCnpjList,
}: CnpjActionsRowProps) => {
  return (
    <StyledCampaignFormRow
      sx={{
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        display: 'flex',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <FileUploadButton
          onClick={onUploadClick}
          onChange={onFileChange}
          inputRef={fileInputRef}
        />
        {isCnpjList && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
              cursor: 'pointer',
              color: '#2B52DD',
              gap: 1,
            }}
          >
            <DownloadButton
              label="Baixar Lista Atual.xls"
              onClick={onDownload}
            />
            <DeleteButton onClick={onDelete} />
          </Box>
        )}
      </Box>
      <DownloadButton
        label="Baixar lista padrão (.xls)"
        onClick={onDownloadDefault}
      />
    </StyledCampaignFormRow>
  );
};
