import { Advertiser } from '../advertiser';
import { CampaignBanner } from '../banners';
import { CampaignSegment } from '../segments';
import { DbDate } from '../types/DbDate.type';

export enum ProductStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DRAFT = 'DRAFT',
  REVIEW = 'REVIEW',
  ATTENTION = 'ATTENTION',
  PAUSED = 'PAUSED',
  DONE = 'DONE',
}
export type Product = {
  id: string;
  name: string;
  advertiser: Advertiser;
  totalInvestment: number;

  startDate: DbDate;
  endDate: DbDate;

  status: `${ProductStatus}`;

  isApp: boolean;
  isWeb: boolean;

  segments: CampaignSegment;
  hasProdutosPatrocinados?: boolean;
  hasCnpjSegmented?: boolean;

  appLink: string | null;
  webLink: string | null;

  appLinkActive: boolean;
  webLinkActive: boolean;

  appBanners: CampaignBanner[];
  webBanners: CampaignBanner[];

  impressions: number[];
  dayOfWeek: string[];

  deleted: boolean;

  createdAt: DbDate;
  updatedAt: DbDate;
  deletedAt: DbDate;
};

export type ProductShort = Pick<
  Product,
  | 'id'
  | 'name'
  | 'advertiser'
  | 'status'
  | 'startDate'
  | 'endDate'
  | 'isApp'
  | 'isWeb'
  | 'impressions'
>;

export type SponsoredProductsResume = Pick<Product, 'id'> & {
  campaignName: Product['name'];
  advertiserName: Advertiser['name'];
  calculateCTR: number;
  impressions: string;
  clicks: string;
  ctr: string;
};

export type SponsoredProductsReport = {
  idCampaign: Product['id'];
  nameCampaign: Product['name'];
  device: string;
  isApp?: boolean;
  isWeb?: boolean;
  impressions: string;
  clicks: string;
  ctr: string;
  cpm: string;
  cpi: string;
  cpc: string;
};

export type ProductList = {
  id: string;
  sku: string;
  campanhaId: number;
  palavraChave: string;
  score: number;
  nome: string;
};

export type ProductDeleteList = Pick<ProductList, 'id'>;

export type ProductModelView = Product;
