import { useRouter } from 'next/navigation';
import { TableCell, TableRow } from '@mui/material';

import { ProcessedName, StyledIdTableCell } from '~/shared';

import { UseGetProductsReturnList } from '../../hooks';

export type ProductRowProps = {
  product: UseGetProductsReturnList[number];
};

export function ProductRow({ product }: ProductRowProps) {
  const router = useRouter();

  return (
    <TableRow
      key={product?.id}
      sx={{
        cursor: 'pointer',
        '&:hover': {
          '.MuiTableCell-body': {
            backgroundColor: theme => theme.palette.grey[100],
          },
        },
        '.MuiTableCell-body': {
          backgroundColor: 'background.paper',
        },
      }}
    >
      <StyledIdTableCell
        component="th"
        scope="row"
        align="center"
        onClick={() => router.push(`/patrocinados/${product.id}`)}
      >
        {product?.sku}
      </StyledIdTableCell>
      <TableCell
        component="th"
        scope="row"
        sx={{
          position: 'sticky',
          left: 0,
        }}
        onClick={() => router.push(`/patrocinados/${product.id}`)}
      >
        <ProcessedName name={product?.nome ?? 'Buscando por produto...'} />
      </TableCell>
      <TableCell
        component="th"
        scope="row"
        align="center"
        onClick={() => router.push(`/patrocinados/${product.id}`)}
      >
        {product?.palavraChave}
      </TableCell>
      <TableCell
        component="th"
        scope="row"
        align="center"
        onClick={() => router.push(`/patrocinados/${product.id}`)}
      >
        {product?.score}
      </TableCell>
    </TableRow>
  );
}
