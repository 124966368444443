import { serviceApi } from '~/features';
import { clearParams } from '~/shared';

export async function downloadDefaultProductList(params = {}) {
  const clearedParams = clearParams(params);
  const response = await serviceApi.get<Blob>(
    `CampanhaProdutosPatrocinados/DownloadProdutosPatrocinadosTemplate`,
    {
      responseType: 'blob',
      params: clearedParams,
    }
  );
  return response;
}
