import { Autocomplete, TextField } from '@mui/material';

import { SegmentType } from '~/types';
import { SEGMENTS } from '~/types/constants/segments';

interface SelectTypeSegmentationProps {
  id: string;
  disabled: boolean;
  value: SegmentType | null;
  onChange: (newValue: SegmentType | null) => void;
  blockedSegments: string[];
  isFirstSelectionSegment: boolean;
  onClear: () => void;
}

export const SelectTypeSegmentation = ({
  id,
  disabled,
  value,
  onChange,
  blockedSegments,
  isFirstSelectionSegment,
  onClear,
}: SelectTypeSegmentationProps) => {
  return (
    <Autocomplete
      sx={{ flexGrow: 1, flexBasis: '30%' }}
      id={id}
      disabled={disabled}
      options={SEGMENTS}
      onInputChange={(_, __, reason) => {
        if (reason === 'clear') {
          onClear();
        }
      }}
      value={value}
      onChange={(_, newValue) => onChange(newValue)}
      getOptionDisabled={option =>
        blockedSegments.includes(option.key) ||
        (option.key === 'cnpj' && !isFirstSelectionSegment)
      }
      getOptionLabel={option => option.name}
      renderInput={params => (
        <TextField {...params} variant="filled" label="Tipo de segmentação" />
      )}
    />
  );
};
