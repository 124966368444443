import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

import { UploadParams } from '~/types';

export function useUploadCnpjSponsoredProductsExcel() {
  return useMutation({
    mutationFn: async ({ file, id, token }: UploadParams) => {
      const formData = new FormData();
      formData.append('file', file);

      const response = await axios.patch(
        `${process.env.NEXT_PUBLIC_SERVICE_API_URL}/api/v1/CampanhaProdutosPatrocinados/UpdateCnpjExcel/${id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            ...(token ? { Authorization: `Bearer ${token}` } : {}),
          },
        }
      );

      return response.data;
    },
  });
}
