import { Autocomplete, Box, TextField } from '@mui/material';

import { StyledCampaignFormRow } from '~/entities/Campaign/components';
import { SelectCity } from '~/entities/SegmentsWithCity/components/Selects/SelectCity';
import { SegmentFromListCampaign } from '~/types';

interface CitySelectionProps {
  state: { id: string; name: string };
  onCityChange: (value: { id: string; name: string }[]) => void;
  segments: SegmentFromListCampaign[];
}

export const CitySelection = ({
  state,
  onCityChange,

  segments,
}: CitySelectionProps) => {
  const segmentField = segments.find(
    segment => segment.segmentType?.name === 'Estado'
  );

  if (!segmentField) return null;

  const selectedCities =
    segmentField.segmentSelections?.find(selection => selection.id === state.id)
      ?.cidades ?? [];
  return (
    <Box key={`box-${state.id}`}>
      <StyledCampaignFormRow key={`segment-row-${state.id}`}>
        <Autocomplete
          sx={{ flexGrow: 1, flexBasis: '30%' }}
          options={[]}
          disabled
          value={state}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          getOptionLabel={option => option.name}
          renderInput={params => (
            <TextField {...params} variant="filled" label="Estado" />
          )}
        />

        <SelectCity
          key="select"
          id={`select-city-${segmentField.id}`}
          label="Cidade"
          onChange={onCityChange}
          value={selectedCities}
          idEstado={state.id}
        />
      </StyledCampaignFormRow>
    </Box>
  );
};
