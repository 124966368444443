'use client';

import { useEffect, useRef, useState } from 'react';
import { useDebounce } from 'react-use';
import { useSession } from 'next-auth/react';
import { Box, TextField } from '@mui/material';
import { isEqual } from 'lodash';

import { SelectState } from '~/entities/SegmentsWithCity';
import { CitySelection } from '~/entities/SegmentsWithCity/components/CitySelection';
import { CnpjActionsRow } from '~/entities/SegmentsWithCity/components/CnpjActionsRow';
import { SegmentActions } from '~/entities/SegmentsWithCity/components/SegmentActions';
import SegmentRow from '~/entities/SegmentsWithCity/components/SegmentRow';
import { uniqueId } from '~/shared';
import { isSegmentEmpty } from '~/shared/utils/isSegmentEmpty';
import type {
  CampaignSegment,
  CampaignSegmentOption,
  SegmentFromListCampaign,
} from '~/types';
import { SEGMENTS } from '~/types/constants/segments';

import { CampaignDeleteCnpjExcelModal } from '../../../SegmentsWithCity/dialogs/DeleteExcel';
import { CampaignErrorExcelModal } from '../../../SegmentsWithCity/dialogs/ErrorExcel';
import { CampaignUpdatedExcelModal } from '../../../SegmentsWithCity/dialogs/UpdatedExcel';
import {
  StyledCampaignFormRow,
  StyledCampaignFormSubtitle,
} from '../../components/elements';
import { useGetCampaign, useUpdateCampaign } from '../../hooks';
import { deleteCnjpsList } from '../../hooks/useDeleteCnpjList';
import { downloadCurrentCnjpsList } from '../../hooks/useGetDownloadCurrentListCNPJ';
import { downloadDefaultCnpjList } from '../../hooks/useGetDownloadDefaultListCNPJ';
import { useUploadCnpjExcel } from '../../hooks/useUploadCnpjExcel';

export function RowSegments({
  campaign,
  updateCampaignQuery,
}: {
  campaign: ReturnType<typeof useGetCampaign>['data'];
  updateCampaignQuery: ReturnType<typeof useUpdateCampaign>;
}) {
  const session = useSession();
  const [segments, setSegments] = useState<SegmentFromListCampaign[]>([]);

  const [blockedSegments, setBlockedSegments] = useState<string[]>([]);
  const [selectedStates, setSelectedState] = useState<CampaignSegmentOption[]>(
    []
  );
  const [isCNPJSelected, setIsCNPJSelected] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [uploadStatus, setUploadStatus] = useState<
    'idle' | 'success' | 'error'
  >('idle');
  const [responseInfo, setResponseInfo] = useState<{
    upload?: { mensagem?: string | null };
    delete?: { message?: string | null };
  }>({});

  const [isDeleteCnpjList, setIsDeleteCnpjList] = useState(false);
  const getCampaignQuery = useGetCampaign(campaign!.id);
  const isCnpjList = getCampaignQuery?.data?.hasCnpjSegmented;
  const [isFirstSelectionSegment, setIsFirstSelectionSegment] = useState(true);
  const uploadCnpjCampaignExcel = useUploadCnpjExcel();

  const handleAddSegment = (index: number) => {
    if (isCNPJSelected) return;

    setSegments(prevSegments => [
      ...prevSegments.slice(0, index + 1),
      { id: uniqueId() },
      ...prevSegments.slice(index + 1),
    ]);

    setIsFirstSelectionSegment(false);
  };

  function handleRemoveSegment(index: number) {
    if (index === 1) {
      setIsFirstSelectionSegment(true);
    }

    const removedSegmentKey = segments[index].segmentType?.key;
    setSegments(state => state.filter((_, i) => i !== index));
    setBlockedSegments(state => state.filter(key => key !== removedSegmentKey));
  }

  const handleUpload = async (selectedFile: File) => {
    const token = session?.data?.t.st;

    const formData = new FormData();
    formData.append('file', selectedFile);

    const { id } = campaign!;

    try {
      const response = await uploadCnpjCampaignExcel.mutateAsync({
        file: selectedFile,
        id,
        token,
      });

      setResponseInfo(prev => ({ ...prev, upload: response }));
      setUploadStatus('success');
    } catch (error) {
      console.error('Error uploading file:', error);
      setUploadStatus('error');
    }
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      await handleUpload(selectedFile);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  function handleOnCloseModalUploadSuccess() {
    setUploadStatus('idle');
  }

  function handleOnCloseModalDeleteSuccess() {
    setIsDeleteCnpjList(false);
  }

  function handleOnCloseModalError() {
    setUploadStatus('idle');
  }

  useEffect(() => {
    if (!campaign?.segments) return;

    if (segments.length === 0 && isSegmentEmpty(campaign.segments)) {
      setSegments([{ id: uniqueId() }]);
    }
    if (!isSegmentEmpty(campaign.segments) && segments.length === 0) {
      const segmentsFromCampaign = Object.keys(campaign.segments)
        .map(key => {
          const isEmpty =
            campaign.segments[key as keyof CampaignSegment].length === 0;
          if (isEmpty) return null;
          return {
            id: uniqueId(),
            segmentType: SEGMENTS.find(segment => segment.key === key) ?? null,
            segmentSelections:
              campaign.segments[key as keyof CampaignSegment] ?? null,
          };
        })
        .filter(item => item !== null) as SegmentFromListCampaign[];

      setSegments(segmentsFromCampaign);
    }
  }, [campaign?.segments, segments.length]);

  const handleDownload = async () => {
    try {
      const response = await downloadCurrentCnjpsList(campaign!.id);

      const blob = new Blob([response.data], {
        type: response.headers['content-type'],
      });
      const downloadUrl = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = 'Cnpjs-Active-Export';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      console.error(err);
    }
  };

  const handleDownloadDefault = async () => {
    try {
      const response = await downloadDefaultCnpjList(campaign?.id);

      const blob = new Blob([response.data], {
        type: response.headers['content-type'],
      });
      const downloadUrl = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = 'Cnpjs-Template-Export';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      console.error(err);
    }
  };

  const handleDelete = async () => {
    try {
      const response = await deleteCnjpsList(campaign!.id);
      setResponseInfo({
        delete: {
          message: response.data.message,
        },
      });
      setIsDeleteCnpjList(true);
    } catch (err) {
      console.error(err);
    }
  };

  const handleUpdateAfterUpload = async () => {
    await getCampaignQuery.refetch();
    setUploadStatus('idle');
  };

  const handleUpdateAfterDelete = async () => {
    await getCampaignQuery.refetch();
    setIsDeleteCnpjList(false);
  };

  useEffect(() => {
    const newBlockedSegments = segments
      .map(item => item.segmentType?.key)
      .filter(item => item !== undefined) as string[];

    setBlockedSegments(newBlockedSegments);
  }, [segments]);

  useDebounce(
    () => {
      if (!campaign?.segments) return;

      const allSegmentsFromCampaign =
        Object.keys(campaign.segments)?.flatMap(key => {
          return campaign.segments[key as keyof CampaignSegment].flatMap(
            segment => {
              const { id: estadoId } = segment;
              if (key === 'estado' && 'cidades' in segment) {
                const { cidades = [] } = segment;
                if (cidades.length === 0) {
                  return [{ id: estadoId }];
                }
                return cidades.map(({ id: cidadeId }) => ({
                  id: estadoId,
                  cidadeId,
                }));
              }
              return { id: estadoId };
            }
          );
        }) ?? [];

      const allSegmentsSelectionIds = segments.flatMap(
        ({ segmentSelections }) => {
          return (
            segmentSelections?.flatMap(({ id: estadoId, cidades = [] }) => {
              if (cidades.length > 0) {
                return cidades.map(({ id: cidadeId }) => ({
                  id: estadoId,
                  cidadeId,
                }));
              }
              return [{ id: estadoId }];
            }) ?? []
          );
        }
      );

      segments.forEach(segmentField => {
        if (segmentField.segmentType?.name === 'Estado') {
          setSelectedState(segmentField.segmentSelections ?? []);
        }
      });

      if (!isEqual(allSegmentsFromCampaign, allSegmentsSelectionIds)) {
        updateCampaignQuery.mutate({
          id: campaign.id,
          segments: allSegmentsSelectionIds,
        });
      }
    },
    1000,
    [segments, campaign?.segments]
  );

  useEffect(() => {
    if (isCnpjList) {
      setIsCNPJSelected(true);
      setSegments([
        {
          id: uniqueId(),
          segmentType:
            SEGMENTS.find(segment => segment.key === 'cnpj') ?? undefined,
        },
      ]);
      setBlockedSegments(
        SEGMENTS.filter(segment => segment.key !== 'cnpj').map(
          segment => segment.key
        )
      );
    }
  }, [isCnpjList]);

  return (
    <>
      <StyledCampaignFormSubtitle>Segmentação</StyledCampaignFormSubtitle>

      {segments.map((segmentField, index) => (
        <StyledCampaignFormRow key={`segment-row-${segmentField.id}`}>
          <SegmentRow
            key={`segment-row-${segmentField.id}`}
            segmentField={segmentField}
            index={index}
            isCnpjList={isCnpjList!}
            blockedSegments={blockedSegments}
            isFirstSelectionSegment={isFirstSelectionSegment}
            setSegments={setSegments}
            setIsCNPJSelected={setIsCNPJSelected}
            setSelectedState={setSelectedState}
          />

          {!segmentField.segmentType && (
            <TextField
              label="Selecione uma segmentação"
              disabled
              fullWidth
              variant="filled"
            />
          )}

          {segmentField.segmentType &&
            segmentField.segmentType.key !== 'cnpj' && (
              <SelectState
                id={segmentField.segmentType.key}
                label={segmentField.segmentType.name}
                segment={segmentField.segmentType.key as keyof CampaignSegment}
                onChange={value => {
                  if (segmentField?.segmentType?.name === 'Estado') {
                    setSelectedState(value);
                  }
                  setSegments(prevSegments => {
                    const newSegments = [...prevSegments];
                    newSegments[index].segmentSelections = value;
                    return newSegments;
                  });
                }}
                value={segmentField.segmentSelections ?? []}
              />
            )}

          {segmentField.segmentType?.key === 'cnpj' && (
            <CnpjActionsRow
              onUploadClick={handleClick}
              onFileChange={handleFileChange}
              fileInputRef={fileInputRef}
              onDownload={handleDownload}
              onDelete={handleDelete}
              onDownloadDefault={handleDownloadDefault}
              isCnpjList={isCnpjList!}
            />
          )}
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              alignItems: 'center',
            }}
          >
            <SegmentActions
              onAddSegment={() => handleAddSegment(index)}
              onRemoveSegment={() => {
                if (segmentField?.segmentType?.name === 'Estado') {
                  setSelectedState([]);
                }
                handleRemoveSegment(index);
              }}
              canAddSegment={segments.length <= 2 && !isCNPJSelected}
              canRemoveSegment={segments.length > 1 && !isCNPJSelected}
            />
          </Box>
        </StyledCampaignFormRow>
      ))}

      {selectedStates.length > 0 && (
        <StyledCampaignFormSubtitle>
          Selecione a cidade
        </StyledCampaignFormSubtitle>
      )}

      {selectedStates.map(state => {
        return (
          <CitySelection
            key={`city-selection-${state.id}`}
            state={state}
            segments={segments}
            onCityChange={value => {
              setSegments(prevSegments => {
                const newSegments = [...prevSegments];

                const stateSegmentIndex = newSegments.findIndex(
                  segment => segment.segmentType?.name === 'Estado'
                );

                if (stateSegmentIndex !== -1) {
                  const updatedSegmentSelections = newSegments[
                    stateSegmentIndex
                  ].segmentSelections?.map(selection => {
                    if (selection.id === state.id) {
                      return { ...selection, cidades: value };
                    }
                    return selection;
                  });

                  newSegments[stateSegmentIndex].segmentSelections =
                    updatedSegmentSelections;
                }

                return newSegments;
              });
            }}
          />
        );
      })}

      {uploadStatus === 'success' && (
        <CampaignUpdatedExcelModal
          mensagem={responseInfo.upload?.mensagem}
          handleUpdateAfterUpload={handleUpdateAfterUpload}
          onCloseModal={handleOnCloseModalUploadSuccess}
        />
      )}

      {isDeleteCnpjList && (
        <CampaignDeleteCnpjExcelModal
          message={responseInfo.delete?.message}
          handleUpdateAfterDelete={handleUpdateAfterDelete}
          onCloseModal={handleOnCloseModalDeleteSuccess}
        />
      )}

      {uploadStatus === 'error' && (
        <CampaignErrorExcelModal onCloseModal={handleOnCloseModalError} />
      )}
    </>
  );
}
