import { CampaignSegment } from '~/types';

export function isSegmentEmpty(segments: CampaignSegment | undefined) {
  let isEmpty = !segments ? true : Object.keys(segments).length === 0;
  if (!isEmpty && segments) {
    isEmpty = Object.keys(segments).every(
      key => segments[key as keyof CampaignSegment].length === 0
    );
  }
  return isEmpty;
}
