import { Box, Button } from '@mui/material';

import { Dialog, RedX } from '~/shared';

interface IProductErrorExcelModal {
  onCloseModal: () => void;
}

export function ProductErrorExcelModal({
  onCloseModal,
}: IProductErrorExcelModal) {
  return (
    <Dialog
      title="Erro na validação da planilha"
      withIcon={<RedX size={70} />}
      open
      withCloseButton
      onClose={onCloseModal}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', mt: 4, gap: 2 }}>
        <Button
          size="large"
          variant="contained"
          sx={{ px: 3, py: 1.5 }}
          onClick={onCloseModal}
        >
          Ok
        </Button>
      </Box>
    </Dialog>
  );
}
