import { Typography } from '@mui/material';

import { DeleteIcon } from '~/shared';

interface DeleteButtonProps {
  onClick: () => void;
}

export const DeleteButton = ({ onClick }: DeleteButtonProps) => {
  return (
    <Typography onClick={onClick}>
      <DeleteIcon color="#2B52DD" size={18} />
    </Typography>
  );
};
