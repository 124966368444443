import { serviceApi } from '~/features';

export async function downloadCurrentCnjpsList(campanhaId: string) {
  const response = await serviceApi.get<Blob>(
    `Campanha/CnpjSegmented-Export/${campanhaId}`,
    {
      responseType: 'blob',
    }
  );
  return response;
}
