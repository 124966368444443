import React from 'react';
import { uniqueId } from 'lodash';

import {
  CampaignSegmentOption,
  SegmentFromListCampaign,
  SegmentSelections,
  SegmentType,
} from '~/types';

import { SelectTypeSegmentation } from './Selects/SelectTypeSegmentation';

interface Segment {
  id: string;
  segmentType?: SegmentType | null;
  segmentSelections?: SegmentSelections[];
}

interface SegmentRowProps {
  segmentField: Segment;
  index: number;
  isCnpjList: boolean;
  blockedSegments: string[];
  isFirstSelectionSegment: boolean;
  setSegments: React.Dispatch<React.SetStateAction<SegmentFromListCampaign[]>>;
  setIsCNPJSelected: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedState: React.Dispatch<
    React.SetStateAction<CampaignSegmentOption[]>
  >;
}
const SegmentRow = ({
  segmentField,
  index,
  isCnpjList,
  blockedSegments,
  isFirstSelectionSegment,
  setSegments,
  setIsCNPJSelected,
  setSelectedState,
}: SegmentRowProps) => {
  return (
    <SelectTypeSegmentation
      id={`segment-type_${segmentField.id}`}
      disabled={isCnpjList}
      value={segmentField.segmentType ?? null}
      onChange={newValue => {
        setSegments(prevSegments => {
          const newSegments = [...prevSegments];

          if (!newSegments[index]) {
            newSegments[index] = { id: uniqueId() };
          }

          newSegments[index].segmentType = newValue ?? undefined;
          newSegments[index].segmentSelections = [];

          if (newValue?.key === 'cnpj') {
            setIsCNPJSelected(true);
            setSelectedState([]);
            setSegments([newSegments[index]]);
          } else {
            setIsCNPJSelected(false);
          }

          return newSegments;
        });
      }}
      blockedSegments={blockedSegments}
      isFirstSelectionSegment={isFirstSelectionSegment}
      onClear={() => setSelectedState([])}
    />
  );
};

export default SegmentRow;
