import { Box, Button } from '@mui/material';

import { Dialog, GreenCheck } from '~/shared';

interface ICampaignUpdatedExcelModal {
  mensagem?: string | null;
  onCloseModal: () => void;
  handleUpdateAfterUpload: () => void;
}

export function CampaignUpdatedExcelModal({
  mensagem,
  onCloseModal,
  handleUpdateAfterUpload,
}: ICampaignUpdatedExcelModal) {
  return (
    <Dialog
      title={mensagem || 'Cnpjs atualizados com sucesso'}
      withIcon={<GreenCheck size={70} />}
      open
      withCloseButton
      onClose={onCloseModal}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', mt: 4, gap: 2 }}>
        <Button
          size="large"
          variant="contained"
          sx={{ px: 3, py: 1.5 }}
          onClick={handleUpdateAfterUpload}
        >
          Ok
        </Button>
      </Box>
    </Dialog>
  );
}
