'use client';

import { type Dispatch, type SetStateAction, useState } from 'react';
import Link from 'next/link';
import {
  Box,
  Button,
  FormControlLabel,
  TextField,
  Typography,
} from '@mui/material';

import { CurrencyTextField, DefaultSwitch } from '~/shared';
import type { Advertiser, CreateSponsoredProductDto } from '~/types';

import { AdvertisersSelect } from '../../Advertiser/components/AdvertisersSelect';
import {
  StyledCampaignForm,
  StyledCampaignFormLabelTitle,
  StyledSelectCampaignTypeBox,
} from '../../Campaign/components/elements';

export type SponsoredProductsFormStepCreationProps = {
  isAppCampaign: boolean;
  setIsAppCampaign: Dispatch<SetStateAction<boolean>>;
  isWebCampaign: boolean;
  setIsWebCampaign: Dispatch<SetStateAction<boolean>>;
  continueAction: (createObject: CreateSponsoredProductDto) => void;
  isLoadingAction: boolean;
};

export function SponsoredProductsFormStepCreation({
  isAppCampaign,
  setIsAppCampaign,
  isWebCampaign,
  setIsWebCampaign,
  continueAction,
  isLoadingAction,
}: SponsoredProductsFormStepCreationProps) {
  const [campaignName, setCampaignName] = useState('');
  const [campaignNameError, setCampaignNameError] = useState<string | null>(
    null
  );

  const [advertiser, setAdvertiser] = useState<Advertiser | null>(null);
  const [advertiserError, setAdvertiserError] = useState<string | null>(null);

  const [campaignValue, setCampaignValue] = useState<number | null>(null);
  const [campaignValueError, setCampaignValueError] = useState<string | null>(
    null
  );

  const [campaignTypeError, setCampaignTypeError] = useState<string | null>(
    null
  );

  function handleFormValidation() {
    let hasError = false;

    if (!campaignName) {
      setCampaignNameError('Informe o nome da campanha');
      hasError = true;
    } else {
      setCampaignNameError(null);
    }

    if (!advertiser) {
      setAdvertiserError('Selecione um anunciante');

      hasError = true;
    } else {
      setAdvertiserError(null);
    }

    if (!campaignValue) {
      setCampaignValueError('Informe o valor da campanha');
      hasError = true;
    } else {
      setCampaignValueError(null);
    }

    if (!isWebCampaign && !isAppCampaign) {
      setCampaignTypeError('Selecione ao menos um tipo de campanha');

      hasError = true;
    } else {
      setCampaignTypeError(null);
    }

    return !hasError;
  }

  function cleanErrors() {
    setCampaignNameError(null);
    setAdvertiserError(null);
    setCampaignValueError(null);
    setCampaignTypeError(null);
  }

  function handleContinue() {
    const isFormValid = handleFormValidation();
    if (isFormValid && advertiser && campaignValue) {
      cleanErrors();
      continueAction({
        advertiserId: advertiser.id,
        name: campaignName,
        totalInvestment: campaignValue,
        isApp: isAppCampaign,
        isWeb: isWebCampaign,
      });
    }
  }

  return (
    <StyledCampaignForm>
      <TextField
        value={campaignName}
        onChange={event => {
          setCampaignNameError(null);
          setCampaignName(event.target.value);
        }}
        id="campaign-name"
        label="Nome da Campanha"
        variant="filled"
        error={!!campaignNameError}
        helperText={campaignNameError}
      />

      <AdvertisersSelect
        id="advertiser"
        value={advertiser}
        advertiserError={advertiserError}
        onChange={selectedAdvertiser => {
          setAdvertiserError(null);
          setAdvertiser(selectedAdvertiser);
        }}
      />

      <CurrencyTextField
        id="campaign-value"
        label="Valor da campanha"
        variant="filled"
        value={campaignValue}
        onChange={event => {
          setCampaignValueError(null);
          setCampaignValue(Number(event.target.value));
        }}
        error={!!campaignValueError}
        helperText={campaignValueError}
      />

      <StyledSelectCampaignTypeBox
        sx={{
          border: campaignTypeError ? '1px solid red' : '1px solid transparent',
        }}
      >
        <FormControlLabel
          checked={isWebCampaign}
          onChange={(_, newValue) => {
            setCampaignTypeError(null);
            setIsWebCampaign(newValue);
          }}
          control={<DefaultSwitch />}
          label={
            <StyledCampaignFormLabelTitle>
              Campanha Web
            </StyledCampaignFormLabelTitle>
          }
        />
        <FormControlLabel
          checked={isAppCampaign}
          onChange={(_, newValue) => {
            setCampaignTypeError(null);
            setIsAppCampaign(newValue);
          }}
          control={<DefaultSwitch />}
          label={
            <StyledCampaignFormLabelTitle>
              Campanha App
            </StyledCampaignFormLabelTitle>
          }
        />
        {!!campaignTypeError && (
          <>
            <Box sx={{ flexGrow: 1 }} />
            <Typography
              sx={{ color: theme => theme.palette.error.main, mt: 1 }}
            >
              {campaignTypeError}
            </Typography>
          </>
        )}
      </StyledSelectCampaignTypeBox>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
        <Link href="/patrocinados" passHref>
          <Button
            size="large"
            sx={{ px: 3, py: 1.5 }}
            disabled={isLoadingAction}
          >
            Cancelar
          </Button>
        </Link>

        <Button
          size="large"
          sx={{ px: 3, py: 1.5 }}
          variant="contained"
          onClick={() => handleContinue()}
          disabled={isLoadingAction}
        >
          Criar Campanha
        </Button>
      </Box>
    </StyledCampaignForm>
  );
}
