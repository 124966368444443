import {
  Autocomplete,
  Checkbox,
  Chip,
  CircularProgress,
  TextField,
} from '@mui/material';

import { CheckboxCheckedIcon, CheckboxIcon } from '~/shared';
import type { CityOption } from '~/types';

import { useGetSegments } from '../../hooks';

interface LiProps extends React.HTMLAttributes<HTMLLIElement> {
  key?: string | number;
}

export type SelectCitytProps = {
  id: string;
  label: string;
  value?: CityOption[];
  onChange: (value: CityOption[]) => void;
  idEstado: string;
  multiple?: boolean;
  disabled?: boolean;
};

export function SelectCity({
  id,
  value = [],
  onChange,
  label,
  idEstado,
  disabled,
}: SelectCitytProps) {
  const { isLoading, isError, data } = useGetSegments();

  function getCitiesByEstadoId(): CityOption[] {
    return (
      data?.estado
        .filter(e => e.id === idEstado)
        .flatMap(t => t.cidades || []) || []
    );
  }

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      disabled={disabled || isLoading || isError}
      sx={{ flexGrow: 1, flexBasis: '100%' }}
      id={`segment-selections${id ? `_${id}` : ''}`}
      options={getCitiesByEstadoId()}
      getOptionLabel={option => option.name}
      // eslint-disable-next-line @typescript-eslint/no-shadow
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={value}
      onChange={(_, newValue) => onChange(newValue)}
      renderOption={(props: LiProps, option) => {
        const isChecked = value.some(val => val.id === option.id);
        const { key, ...restProps } = props;

        return (
          <li key={option.id} {...restProps}>
            <Checkbox
              icon={CheckboxIcon}
              checkedIcon={CheckboxCheckedIcon}
              style={{ marginRight: 8 }}
              checked={isChecked}
            />
            {option.name}
          </li>
        );
      }}
      renderInput={params => (
        <TextField
          {...params}
          variant="filled"
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? (
                  <CircularProgress
                    color="inherit"
                    size={25}
                    sx={{ mt: '-18px' }}
                  />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderTags={(v, getTagProps) =>
        v.map((option, index) => {
          const { key, ...tagProps } = getTagProps({ index });
          return <Chip key={option.id} label={option.name} {...tagProps} />;
        })
      }
    />
  );
}
