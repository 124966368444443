import { serviceApi } from '~/features';
import { clearParams } from '~/shared';

export async function downloadDefaultCnpjListProducts(params = {}) {
  const clearedParams = clearParams(params);
  const response = await serviceApi.get<Blob>(`Campanha/DownloadCNPJTemplate`, {
    responseType: 'blob',
    params: clearedParams,
  });
  return response;
}
