import { Button, styled } from '@mui/material';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
interface FileUploadButtonProps {
  onClick: () => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  inputRef: React.RefObject<HTMLInputElement>;
}

export const FileUploadButton = ({
  onClick,
  onChange,
  inputRef,
}: FileUploadButtonProps) => {
  return (
    <>
      <VisuallyHiddenInput
        type="file"
        accept=".xls,.xlsx,.xlsm"
        onChange={onChange}
        ref={inputRef}
      />
      <Button
        size="large"
        variant="contained"
        sx={{ px: 3, py: 1.5 }}
        onClick={onClick}
      >
        Enviar Lista (.xls)
      </Button>
    </>
  );
};
