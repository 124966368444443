import { Advertiser } from '../advertiser';
import { CampaignBanner } from '../banners';
import { CampaignSegment } from '../segments';
import { DbDate } from '../types/DbDate.type';

export enum CampaignStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DRAFT = 'DRAFT',
  REVIEW = 'REVIEW',
  ATTENTION = 'ATTENTION', // temporarily disabled
  PAUSED = 'PAUSED',
  DONE = 'DONE',
}

export type Campaign = {
  id: string;
  name: string;
  advertiser: Advertiser;
  totalInvestment: number;

  startDate: DbDate;
  endDate: DbDate;

  status: `${CampaignStatus}`;

  isApp: boolean;
  isWeb: boolean;
  hasCnpjSegmented?: boolean;

  segments: CampaignSegment;

  appLink: string | null;
  webLink: string | null;

  appLinkActive: boolean;
  webLinkActive: boolean;

  appBanners: CampaignBanner[];
  webBanners: CampaignBanner[];

  impressions: number[];
  dayOfWeek: string[];

  deleted: boolean;

  createdAt: DbDate;
  updatedAt: DbDate;
  deletedAt: DbDate;
};

export type CampaignShort = Pick<
  Campaign,
  | 'id'
  | 'name'
  | 'advertiser'
  | 'status'
  | 'startDate'
  | 'endDate'
  | 'isApp'
  | 'isWeb'
  | 'impressions'
>;

export type CampaignResume = Pick<Campaign, 'id'> & {
  campaignName: Campaign['name'];
  advertiserName: Advertiser['name'];
  calculateCTR: number;
  impressions: string;
  clicks: string;
  ctr: string;
};

export type CampaignReport = {
  idCampaign: Campaign['id'];
  nameCampaign: Campaign['name'];
  device: string;
  isApp?: boolean;
  isWeb?: boolean;
  impressions: string;
  clicks: string;
  ctr: string;
  cpm: string;
  cpi: string;
  cpc: string;
};

export type CampaignModelView = Campaign;
