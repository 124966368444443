import { DeleteOutlined as DeleteOutlinedIcon } from '@mui/icons-material';
import { Box, Button, IconButton } from '@mui/material';

interface SegmentActionsProps {
  onAddSegment: () => void;
  onRemoveSegment: () => void;
  canAddSegment: boolean;
  canRemoveSegment: boolean;
}

export const SegmentActions = ({
  onAddSegment,
  onRemoveSegment,
  canAddSegment,
  canRemoveSegment,
}: SegmentActionsProps) => {
  return (
    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
      {canAddSegment && (
        <Button
          variant="outlined"
          sx={{ borderRadius: 3, height: 50 }}
          onClick={onAddSegment}
        >
          E
        </Button>
      )}
      {canRemoveSegment && (
        <IconButton
          color="primary"
          onClick={onRemoveSegment}
          sx={{ width: 50, height: 50 }}
        >
          <DeleteOutlinedIcon />
        </IconButton>
      )}
    </Box>
  );
};
