import { useQuery, useQueryClient } from '@tanstack/react-query';

import { serviceApi } from '~/features';
import { clearParams, clearParamsSort } from '~/shared';
import type { ProductList } from '~/types';
import { ProductListDTO } from '~/types/product/list.dto';
import { ProductListModelView } from '~/types/product/list.mv';

export const GET_PRODUCTS_LIST_QUERY_KEY = 'GET_PRODUCTS_LIST_QUERY';

async function getProductsList(params: ProductListDTO, id?: string) {
  let clearedParams;
  if (
    !params.orderCampaign ||
    !params.orderDevice ||
    !params.orderDevice ||
    !params.orderInitial ||
    !params.orderFinish
  ) {
    clearedParams = clearParamsSort(params);
  } else {
    clearedParams = clearParams(params);
  }

  const response = serviceApi.get<ProductListModelView>(
    `CampanhaProdutosPatrocinados/GetProdutoPatrocinado${id}`,
    {
      params: clearedParams,
    }
  );

  return response;
}

export function useGetProductsList(params: ProductListDTO, id?: string) {
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: [GET_PRODUCTS_LIST_QUERY_KEY, params, id],
    select: data => {
      const returnData = data?.data;

      return {
        ...returnData,
        list: returnData?.list.map(item => {
          return {
            ...item,
            // startDate: parseDate(item.startDate),
            // endDate: parseDate(item.endDate),
            // createdAt: parseDate(item.createdAt),
            // updatedAt: parseDate(item.updatedAt),
            // deletedAt: parseDate(item.deletedAt),
          };
        }),
      };
    },
    queryFn: () => getProductsList(params, `?campanhaId=${id}`),
  });

  return Object.assign(query, {
    refetchAll: () =>
      queryClient.refetchQueries({ queryKey: [GET_PRODUCTS_LIST_QUERY_KEY] }),
  });
}

export type UseGetProductsReturn = ReturnType<
  typeof useGetProductsList
>['data'];

export type UseGetProductsReturnList = Array<ProductList>;
